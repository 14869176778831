import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, PrimaryButton, SegmentedControl, Select, SkeltonButton, WithBlank, } from '../../components';
import { Store } from '../../entities';
import { useAppContext, useStorePlatforms } from '../../hooks';
import styles from '../../styles/partials/forms/BrandForm.module.scss';

enum Option {
  SELECT = 'ブランド選択',
  NEW = '新規ブランドを追加',
}

type FormData = {
  id: string | undefined
  name: string
};

interface BrandFormProps {
  store: { name: string, accountId?: undefined, id?: undefined } | Store | undefined;
  onNext: (brand: FormData) => void;
  onPrev?: () => void;
}

export const BrandForm: React.FC<BrandFormProps> = ({ store, onNext, onPrev }) => {
  const { brands, loading: appContextLoading } = useAppContext();
  const [platforms, platformLoading, platformError] = useStorePlatforms(store?.accountId, store?.id);

  const [selectedOption, selectOption] = useState(Option.NEW);
  const { handleSubmit, control, errors, reset } = useForm<FormData>({ reValidateMode: 'onChange' });

  const selectableBrands = useMemo(() => {
    const integratedBrandIds = platforms
      ?.filter(platform => platform.storeId === store?.id)
      ?.map(platform => platform.brandId)
      ?? [];

    return brands
      ?.filter(brand => !integratedBrandIds.includes(brand.id))
      ?? [];
  }, [store, brands, platforms])

  const selectableOptions = useMemo(
    () => selectableBrands?.length ? [Option.SELECT, Option.NEW] : [Option.NEW],
    [selectableBrands]
  );

  const onClickSegment = (option: string) => {
    const key = Object.keys(Option)
      .map(key => ({ key, value: Option[key as keyof typeof Option] }))
      .find(map => map.value === option)
      ?.key;
    selectOption(Option[key as keyof typeof Option]);
    reset({ name: '' });
  };

  const onSubmit = (data: FormData) => {
    switch (selectedOption) {
      case Option.NEW:
        onNext({ id: undefined, name: data.name });
        break;
      case Option.SELECT: {
        onNext({ id: data.id, name: data.name });
        break;
      }
    }
  };

  return <WithBlank loading={appContextLoading || platformLoading} error={platformError}>
    <div className={styles.form}>
      <section>
        <h2>ブランドを選択してください</h2>
        <SegmentedControl
          options={selectableOptions}
          selectedIndex={selectableOptions.indexOf(selectedOption)}
          onClick={onClickSegment}
        />
        <form>
          {(() => {
            switch (selectedOption) {
              case Option.SELECT:
                return (
                  <Controller
                    name="id"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'ブランドを選択してください' }}
                    render={({ onChange, value }) => (
                      <Select
                        title="ブランド選択"
                        options={selectableBrands.map(brand => ({ value: brand.id, label: brand.name, }))}
                        onChange={onChange}
                        value={value}
                        message={errors.id && errors.id.message}
                      />
                    )}
                  />
                );
              case Option.NEW:
                return (
                  <>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'ブランド名を入力してください' }}
                      render={({ onChange, value }) => (
                        <Input
                          title="新規ブランド名"
                          description="※Orderlyで表示するブランド名です。店舗名や地名は省いた形で記入してください。 ◯「Orderly Cafe」 ✕「Orderly Cafe 渋谷店」"
                          type="text"
                          placeholder="ブランド名を入力してください"
                          onChange={onChange}
                          value={value}
                          message={errors.name && errors.name.message}
                        />
                      )}
                    />
                  </>
                );
            }
          })()}
        </form>
      </section>
      <footer>
        {onPrev ? (<SkeltonButton title="戻る" onClick={() => onPrev?.()} />) : (<div />)}
        <PrimaryButton size='small' title="次へ" onClick={handleSubmit(onSubmit)} />
      </footer>
    </div>
  </WithBlank>
};
