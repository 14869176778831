import React from 'react';
import { ItemList, PlatformSummaryView, PrimaryButton, SkeltonButton } from '../../components';
import { Brand, Item, Store } from '../../entities';
import styles from '../../styles/partials/forms/MenuForm.module.scss';

interface MenuFormProps {
  store: Pick<Store, 'name'> | undefined;
  brand: Pick<Brand, 'name'> | undefined;
  menu: Omit<Item, 'accountId' | 'storeId' | 'brandId'>[] | undefined;
  onNext: () => void;
  onPrev: () => void;
}

export const MenuForm: React.FC<MenuFormProps> = (props: MenuFormProps) =>
  <div className={styles.wrapper}>
    <section className={styles.section}>
      <PlatformSummaryView store={props.store} brand={props.brand} />
    </section>
    <section className={styles.section}>
      <h2 className={styles.title}>メニュー確認</h2>
      <ItemList items={props.menu ?? []}/>
    </section>
    <footer>
      <SkeltonButton title="戻る" onClick={() => props.onPrev()} />
      <PrimaryButton size='small' title="完了" onClick={() => props.onNext()} />
    </footer>
  </div>;
