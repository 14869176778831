import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputFile, PosSummaryView, PrimaryButton, Radio, SegmentedControl, SkeltonButton } from '../../../components';
import { PosTypeDecorator } from '../../../decorators';
import { PosFile, PosPlatformType, Store } from '../../../entities';
import styles from '../../../styles/partials/forms/pos/MenuUploadForm.module.scss';

interface Props {
  store: Pick<Store, 'name'>
  posType: PosPlatformType | undefined
  posFile?: PosFile
  onNextImport: () => void
  onNextEdit: () => void
  onNextUpload: (file: File) => void
  onPrev?: () => void
}

interface FormValues {
  file?: File
}

enum Option {
  Import = 'POSから自動で取得',
  Upload = '手動アップロードで取得'
}

export const MenuUploadForm: React.FC<Props> = (props: Props) => {
  const { handleSubmit, control, errors } = useForm({ reValidateMode: 'onChange' });
  const [selectedOption, selectOption] = useState(Option.Upload);
  const options = props.posType === 'ngp' ? Object.values(Option) : [Option.Upload];

  const onClickSegment = (option: string) => {
    selectOption(option as Option);
  };

  const onSubmit = async (values: FormValues) => {
    switch (selectedOption) {
      case Option.Import:
        props.onNextImport();
        break;
      case Option.Upload:
        values.file ? props.onNextUpload(values.file) : props.onNextEdit();
    }
  };

  return (
    <div className={styles.form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <PosSummaryView
            posName={new PosTypeDecorator(props.posType ?? 'stesso').name()}
            store={props.store}
          />
          <h2>POSのメニューの取得方法を選択してください</h2>
          <SegmentedControl
            options={options}
            selectedIndex={Object.values(Option).indexOf(selectedOption)}
            onClick={onClickSegment}
          />
          {selectedOption === Option.Import && (
            <div className={styles.upload}>
              <Radio
                options={[{ name: 'POSから自動でメニューを取得', value: 'default' }]}
                value='default'
              />
            </div>
          )}
          {selectedOption === Option.Upload && (
            <div className={styles.upload}>
              <Controller
                name='file'
                control={control}
                rules={{ required: props.posFile ? false : 'CSVファイルを選択してください' }}
                render={({ onChange }) => (
                  <InputFile
                    title={props.posFile ? 'CSVアップロードをやり直す' : 'CSVファイルを選択'}
                    onChange={onChange}
                    message={errors.file && errors.file.message}
                  />
                )}
              />
              {props.posFile && (
                <div className={styles.latestPosFile}>
                  <p>{props.posFile?.fileName}</p>
                </div>
              )}
            </div>
          )}
        </section>
        <footer>
          {props.onPrev ? <SkeltonButton title='戻る' onClick={() => props.onPrev && props.onPrev()} /> : <div />}
          <PrimaryButton size='small' title='次へ' onClick={handleSubmit(onSubmit)} />
        </footer>
      </form>
    </div>
  );
};
