import React from 'react';
import styles from '../../styles/components/layouts/Modal.module.scss';
import { If } from '../If';

interface Props {
  isOpen: boolean
  children: React.ReactNode
  onClose: () => void
}

export const Modal = ({ isOpen, children, onClose }: Props): React.ReactElement =>
  <If condition={isOpen}>
    <div id='overlay' className={styles.overlay}>
      <div id='modalContent' className={styles.modalContent}>
        {children}
        <div id='close' className={styles.close} onClick={() => onClose()}>
          <img src='/icon/close.svg'/>
          <span>閉じる</span>
        </div>
      </div>
    </div>
  </If>
