import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { DateTime, Input, PlatformSummaryView, PrimaryButton, Select, SkeltonButton } from '../../components';
import { PlatformTypeDecorator } from '../../decorators';
import { Brand, PlatformType, Store } from '../../entities';
import styles from '../../styles/partials/forms/PlatformForm.module.scss';

type PlatformDataCommon = {
  executeTime: string
}

export type PlatformFormData = PlatformDataCommon &
  ({
    type: 'boteats' | 'menu' | 'ubereats' | 'wolt'
    email: string
    password: string
  } | {
    type: 'menuOfficial'
    uuid: string
  } | {
    type: 'demaecan'
    email: string
    password: string
    adminId: string
    adminCode: string
    adminPassword: string
  } | {
    type: 'demaecanOfficial'
    corpId: string
    uuid: string
    token: string
  } | {
    type: 'sukesan'
    store_code: string
  } | {
    type: 'ubereatsOfficial'
    store_id: string
  } | {
    type: 'woltOfficial'
    email: string
    password: string
    venueId: string
    token: string
  });

interface Props {
  operationType: 'create' | 'edit';
  title: string;
  store: Pick<Store, 'name'> | undefined;
  brand: Pick<Brand, 'name'> | undefined;
  platformTypes: PlatformType[];
  onNext: (_: PlatformFormData) => void;
  onPrev?: () => void;
}

type FormProp = {
  propertyName: string
  defaultValue: string
  rules: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  inputTitle: string
  inputType: 'text' | 'email' | 'password'
  placeholder: string
};

export const PlatformForm: React.FC<Props> = (props: Props) => {
  const { handleSubmit, watch, control, errors, setValue } = useForm({ reValidateMode: 'onChange', });
  const watchType = watch('type', props.platformTypes?.[0]);

  useEffect(() => {
    setValue('type', props.platformTypes?.[0]);
  }, [setValue, props.platformTypes]);

  const onSubmit = useCallback(async (data: PlatformFormData) => {
    if (data.type === 'ubereats' && data.email && !data.email.includes('@')) {
      data.email += '@ubereats.com';
    }
    props.onNext(data);
  }, [props]);

  const FormInput = useCallback(({ propertyName, defaultValue, rules, inputTitle, inputType, placeholder }: FormProp) =>
    <Controller
      name={propertyName}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value }) => (
        <Input
          title={inputTitle}
          type={inputType}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          message={errors[propertyName] && errors[propertyName].message}
        />
      )}
    />, [control, errors]);

  return useMemo(() =>
    <div className={styles.form}>
      <section>
        <PlatformSummaryView store={props.store} brand={props.brand} />
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <h2>{props.title}</h2>
        </section>
        <section className={styles.inputs}>
          <Controller
            name="type"
            control={control}
            defaultValue={props.platformTypes?.[0]}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <Select
                title="プラットフォーム"
                options={props.platformTypes.map((platform) => ({
                  value: platform,
                  label: new PlatformTypeDecorator(platform).name(),
                }))}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {props.operationType === 'create' && (
            <>
              {formPropsFor(watchType)?.map(formProp => <FormInput {...formProp} />)}
            </>
          )}
          <Controller
            name="executeTime"
            control={control}
            defaultValue={dayjs().format('YYYY-MM-DDTHH:mm:ss')}
            rules={{ required: '連携日時を選択してください' }}
            render={({ onChange, value }) => (
              <DateTime
                title='適用日時'
                description='※日時を選択すると、連携操作を予約できます。'
                onChange={onChange}
                value={value}
                message={errors.executeTime && errors.executeTime.message}
              />
            )}/>
        </section>
        <footer>
          {props.onPrev ? (<SkeltonButton title="戻る" onClick={() => props.onPrev?.()} />) : (<div />)}
          <PrimaryButton size='small' title="次へ" onClick={handleSubmit(onSubmit)} />
        </footer>
      </form>
    </div>
  , [onSubmit, FormInput, handleSubmit, watchType, control, errors, props]);
};

const formPropsFor = (platformType: PlatformType): FormProp[] => {
  const emailProps = {
    propertyName: 'email',
    defaultValue: '',
    rules: { required: 'メールアドレスもしくはIDを入力してください' },
    inputTitle: 'メールアドレス or ID',
    inputType: 'email' as const,
    placeholder: 'email'
  };

  const passwordProps = {
    propertyName: 'password',
    defaultValue: '',
    rules: { required: 'パスワードを入力してください' },
    inputTitle: 'パスワード',
    inputType: 'password' as const,
    placeholder: '半角英数字'
  };

  switch(platformType) {
    case 'demaecan':
      return [
        emailProps,
        passwordProps,
        {
          propertyName: 'adminId',
          defaultValue: '',
          rules: { required: '管理画面IDを入力してください' },
          inputTitle: '管理画面ID',
          inputType: 'text',
          placeholder: '半角英数字'
        },
        {
          propertyName: 'adminCode',
          defaultValue: '',
          rules: { required: '管理画面コードを入力してください' },
          inputTitle: '管理画面コード',
          inputType: 'text',
          placeholder: '半角英数字'
        },
        {
          propertyName: 'adminPassword',
          defaultValue: '',
          rules: { required: '管理画面パスワードを入力してください' },
          inputTitle: '管理画面パスワード',
          inputType: 'password',
          placeholder: '半角英数字'
        },
      ];
    case 'demaecanOfficial':
      return [
        {
          propertyName: 'corpId',
          defaultValue: '',
          rules: { required: '会社IDを入力してください' },
          inputTitle: '会社ID',
          inputType: 'text',
          placeholder: '半角英数字'
        },
        {
          propertyName: 'uuid',
          defaultValue: '',
          rules: { required: '店舗IDを入力してください' },
          inputTitle: '店舗ID',
          inputType: 'text',
          placeholder: '半角英数字'
        },
        {
          propertyName: 'token',
          defaultValue: '',
          rules: { required: '認証トークンを入力してください' },
          inputTitle: 'トークン',
          inputType: 'password',
          placeholder: '半角英数字'
        }
      ];
    case 'woltOfficial':
      return [
        emailProps,
        passwordProps,
        {
          propertyName: 'venueId',
          defaultValue: '',
          rules: { required: 'VenueIDを入力してください' },
          inputTitle: 'venueId',
          inputType: 'text',
          placeholder: '半角英数字'
        },
        {
          propertyName: 'token',
          defaultValue: '',
          rules: { required: '認証トークンを入力してください' },
          inputTitle: 'トークン',
          inputType: 'password',
          placeholder: '半角英数字'
        },
      ]
    case 'menuOfficial':
      return [{ ...emailProps, propertyName: 'uuid' }];
    case 'sukesan':
      return [{
        propertyName: 'store_code',
        defaultValue: '',
        rules: { required: '店舗IDを入力してください' },
        inputTitle: '店舗ID',
        inputType: 'text',
        placeholder: '半角英数字'
      }];
    case 'ubereatsOfficial':
      return [{
        propertyName: 'store_id',
        defaultValue: '',
        rules: { required: '店舗IDを入力してください' },
        inputTitle: '店舗ID',
        inputType: 'text',
        placeholder: '半角英数字'
      }];
    case 'boteats':
    case 'menu':
    case 'ubereats':
    case 'wolt':
      return [emailProps, passwordProps];
  }
};
