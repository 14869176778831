import React, { useState } from 'react';
import {
  LineChart,
  SegmentedControl,
  Spinner,
  StackedBarChart,
  StackedBarChartData,
  TabButton,
} from '../../components';
import { PlatformDecorator } from '../../decorators';
import { Platform, StatisticsChart, StatisticsChartData } from '../../entities';
import styles from '../../styles/partials/dashboards/SalesView.module.scss';

interface SalesViewProps {
  statisticsChart?: StatisticsChart;
  loading: boolean;
}

enum Item {
  Sales = '売上',
  Orders = 'オーダー数',
  Cancels = 'キャンセル数',
  Average = '平均単価',
  NewRate = '新規オーダー割合',
}

enum Segment {
  Platform = 'プラットフォーム',
  Brand = 'ブランド',
}

const SalesView: React.FC<SalesViewProps> = ({ statisticsChart, loading }: SalesViewProps) => {
  const [selectedItem, setSelectedItem] = useState(Item.Sales);
  const [segment, setSegment] = useState<Segment>(Segment.Platform);

  const ControllerView = () => {
    return (
      <div className={styles.controller}>
        <TabButton
          items={Object.values(Item)}
          selectedItem={selectedItem}
          onSelect={(item) => setSelectedItem(item as Item)}
        />
        <div className={styles.segment}>
          <SegmentedControl
            options={Object.values(Segment)}
            selectedIndex={Object.values(Segment).indexOf(segment)}
            onClick={(option) => {
              setSegment(option as Segment);
            }}
          />
        </div>
      </div>
    );
  };

  const ChartView = () => {
    const createChartData = (statisticsChartData?: StatisticsChartData): StackedBarChartData[] => {
      if (!statisticsChartData) return [];

      switch (segment) {
        case Segment.Platform:
          return statisticsChartData.platform
            .map((platformData) =>
              platformData.data.map((unitData) => ({
                xField: unitData.k,
                yField: unitData.v,
                groupName: new PlatformDecorator({ type: platformData.id } as Platform).name(),
              })),
            )
            .flat();
        case Segment.Brand:
          return statisticsChartData.brand
            .map((brandData) =>
              brandData.data.map((unitData) => ({
                xField: unitData.k,
                yField: unitData.v,
                groupName: brandData.name,
              })),
            )
            .flat();
      }
    };

    return (
      <div className={styles.chart}>
        {selectedItem === Item.Sales && (
          <StackedBarChart unit="price" data={createChartData(statisticsChart?.earnings)} />
        )}
        {selectedItem === Item.Orders && (
          <StackedBarChart unit="number" data={createChartData(statisticsChart?.order)} />
        )}
        {selectedItem === Item.Cancels && (
          <StackedBarChart
            unit="number"
            data={createChartData(statisticsChart?.canceledOrder)}
          />
        )}
        {selectedItem === Item.Average && (
          <LineChart
            unit="price"
            data={createChartData(statisticsChart?.averageSalesPerCustomer)}
          />
        )}
        {selectedItem === Item.NewRate && (
          <LineChart unit="percent" data={createChartData(statisticsChart?.firstOrderRate)} />
        )}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <ControllerView />
      {
        loading
          ? <Spinner loading size='contain' />
          : <ChartView />
      }
    </div>
  );
};

export { SalesView };
